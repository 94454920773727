import { mutations, actions as parentActions, getters as parentGetters, state } from '~/sites/shop/store/globals'
import get from 'lodash/get'
const actions = {
  ...parentActions,
  CUSTOM_CONFIGURATION_CMS_QUERY() {
    return `
    configuration_banner_secondary {
      enabled
      banner_reference {
        entity {
          ... on banner_catalog {
            url
            domain_enabled
            image {
              alt
              path
            }
          }
        }
        entityId
      }
    }
    configuration_top_banner {
      enabled
      background_color
      domain_enabled
      font_color
      icon
      icon_text
      lastModified
      text
      sticky
    }
    `
  },
  CUSTOM_CONFIGURATION_CMS_FETCH({ commit }, { result }) {
    const data = get(result, 'cms.data')
    commit('SET_FIELD', {
      field: 'secondaryBanner',
      value: get(data, 'configuration_banner_secondary'),
    })
  },
}

const getters = {
  ...parentGetters,
  isSecondaryBannerEnabled: state => {
    return Boolean(state.secondaryBanner?.enabled)
  },
  secondaryBanner: state => {
    return get(state.secondaryBanner, 'banner_reference[0].entity')
  },
}

export { actions, getters, mutations, state }
