import ProductBox from '~/themes/base/components/product/ProductBox'

export default {
  extends: ProductBox,
  computed: {
    showRecommendedPrice() {
      return (
        Boolean(this.activeVariant.prices.attributes?.recommended_price) &&
        this.activeVariant.prices.originalWithTax > this.activeVariant.prices.unitWithTax
      )
    },
  },
}
